<template>
  <section class="head">
    <div class="content">
      <div class="left">
        <a-breadcrumb>
          <a-breadcrumb-item>
            <router-link to="/shop">{{ $t('MN_IntegralMall_MHome') }}</router-link>
            <!-- 积分商城 -->
          </a-breadcrumb-item>
          <a-breadcrumb-item>{{ $t('shop.commodity_detail') }}</a-breadcrumb-item>
          <!-- 商品详情 -->
        </a-breadcrumb>
      </div>
      <div class="right">
        {{ $t('integral.available_integral') }}：
        <!-- 可用积分： -->
        <span class="integral">{{ myIntegral }}</span>
        <router-link to="/shop/order">{{ $t('shop.my_exchange_record') }}</router-link>
        <!-- 我的兑换记录 -->
        <span style="color: #ccc; font-size: 14px; padding: 0 5px">｜</span>
        <router-link to="/shop/rule">{{ $t('shop.integral_rule') }}</router-link>
        <!-- 积分兑换规则 -->
      </div>
    </div>
  </section>

  <section class="goods">
    <div class="content">
      <div class="cover">
        <img :src="giftData.coverImg" alt="cover" />
        <p v-if="giftData.price">{{ $t('cm_price') }}： {{ $t('currency_symbol') }}{{ giftData.price }}</p>
        <!-- 价格 -->
      </div>
      <div class="info">
        <h3>{{ giftData.giftName }}</h3>
        <div class="price">
          <span>
            <!-- 积分 -->
            {{ $t('LB_Pro_Integral') }}&nbsp;&nbsp;<strong>{{ giftData.integral }}</strong>
          </span>
          <span>{{ $t('shop.commodity_stock') }}&nbsp;{{ giftData.stocks }}&nbsp;{{ $t('shop.pieces') }}</span>
          <!-- 商品库存  件 -->
        </div>
        <div class="num">
          <span>{{ $t('CM_quantity') }}</span>
          <!-- 数量 -->
          <div class="input">
            <PlusOutlined class="icon plus" />
            <MinusOutlined class="icon minus" />
            <a-input-number
              v-model:value="buyNums"
              :min="1"
              :max="
                giftData.stocks < giftData.userLimit - giftData.myExchanged
                  ? giftData.stocks
                  : giftData.userLimit - giftData.myExchanged
              "
            />
          </div>
          <span class="tips">
            {{ $t('shop.people_exchange_num', [giftData.userLimit]) }}
            <!-- 此商品每人仅限兑换 件 -->
            （{{ $t('shop.have_exchange_num', [giftData.myExchanged || 0]) }}）
            <!-- 已兑换 件 -->
          </span>
        </div>
        <div class="btn">
          <a-button
            v-if="giftData.stocks == 0"
            type="primary"
            class="none"
            disabled
            >{{ $t('shop.already_exchange') }}</a-button
          >
          <!-- 已经兑完 -->
          <a-button
            v-else-if="giftData.myExchanged == giftData.userLimit"
            type="primary"
            class="none"
            disabled
            >{{ $t('XB_HasExchanged') }}</a-button
          >
          <!-- 已兑换 -->
          <a-button v-else type="primary" class="buy" @click="buy"
            >{{ $t('Lab_Mall_Conversion') }}</a-button
          >
          <!-- 立即兑换 -->
        </div>
      </div>
    </div>
  </section>

  <section class="main">
    <div class="intro">
      <div class="tit">{{ $t('shop.the_product_details') }}</div>
      <!-- 商品详细 -->
      <div>
        <div class="content">
          <div
            class="vhtml"
            v-if="giftData.intro"
            v-html="giftData.intro"
          ></div>
          <a-empty v-else style="padding: 50px 0" />
        </div>
      </div>
    </div>
    <div class="recommend">
      <div class="tit">{{ $t('shop.guess_you_like') }}</div>
      <!-- 猜你喜欢 -->
      <div>
        <div class="content">
          <div v-if="randomGifts.length">
            <template v-for="(item, index) in randomGifts" :key="item.giftId">
              <a
                class="item"
                v-if="item.giftId != giftId && index < 4"
                :href="`/shop/detail?giftId=${item.giftId}${
                  newWindow == 1 ? '&ddtab=true' : ''
                }`"
                :target="newWindow == 1 ? '_blank' : '_self'"
              >
                <div class="cover">
                  <img :src="item.coverImg" alt="cover" />
                </div>
                <div class="info">
                  <h5>{{ item.giftName }}</h5>
                  <div class="bottom">
                    <strong>{{ item.integral }} {{ $t('LB_Pro_Integral') }}</strong>
                    <!-- 积分 -->
                    <i>{{ $t('shop.stock') }}：{{ item.stocks }}</i>
                    <!-- 库存： -->
                  </div>
                </div>
              </a>
            </template>
          </div>
          <a-empty v-else style="padding: 50px 0" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs, createVNode, getCurrentInstance } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import { giftList, giftDetail, giftExchange, giftRule } from "@/api/shop";
export default {
  setup() {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const store = useStore();
    const route = useRoute();
    const state = reactive({
      giftId: Number(route.query.giftId || 0),
      myIntegral: 0,
      randomGifts: [],
      giftData: {},
      buyNums: 1,
    });

    //生成随机数组
    const shuffle = (array) => {
      var m = array.length,
        t,
        i;
      while (m) {
        i = Math.floor(Math.random() * m--);
        t = array[m];
        array[m] = array[i];
        array[i] = t;
      }
      return array;
    };

    giftList({ page: 1, pageSize: 30 }).then((res) => {
      state.randomGifts = shuffle(res.data.list || []);
    });

    const getIntegral = () => {
      giftRule().then((res) => {
        state.myIntegral = res.data.integral || 0;
      });
    };
    getIntegral();

    giftDetail(state.giftId).then((res) => {
      state.giftData = res.data;
    });

    const buy = () => {
      if (state.giftData.integral * state.buyNums > state.myIntegral) {
        proxy.$message.warn($t('shop.integral_not_enough'));
        // 您的积分不足
        return false;
      }
      Modal.confirm({
        title: () => $t('shop.sure_exchange'),
        // 确定兑换吗
        icon: () => createVNode(ExclamationCircleOutlined),
        onOk() {
          giftExchange({
            giftId: state.giftId,
            nums: state.buyNums,
          }).then((res) => {
            if (res.ret == 0) {
              proxy.$message.success($t('CM_ConversionForSuccessful'));
              // 兑换成功
              state.giftData.myExchanged =
                state.giftData.myExchanged + state.buyNums;
              state.giftData.stocks = state.giftData.stocks - state.buyNums;
              state.buyNums = 1;
              getIntegral();
            }
          });
        },
      });
    };

    return {
      newWindow: store.getters.companyInfo.useNewWindow,
      ...toRefs(state),
      buy,
    };
  },
};
</script>

<style lang="less" scoped>
.head {
  .content {
    .mixinWrap();
    height: 65px;
    .mixinFlex(space-between; center);
    .left {
      ::v-deep(.ant-breadcrumb-link) {
        color: #999999;
        a {
          color: #999999;
          &:hover {
            color: @color-theme;
          }
        }
      }
    }
    .right {
      color: #333;
      user-select: none;
      font-size: 16px;
      .mixinFlex(flex-start; center);
      .integral {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #dd0c16;
        margin-right: 34px;
      }
      a {
        font-size: 14px;
        line-height: 21px;
        color: #333;
        &:hover {
          color: @color-theme;
        }
      }
    }
  }
}

.goods {
  .content {
    .mixinWrap();
    padding: 30px;
    background-color: #fff;
    border-radius: 4px;
    .mixinFlex(space-between);
    .cover {
      .mixinImgWrap(461px; 350px);
      p {
        margin: 0;
        padding: 0 24px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-size: 16px;
        background: rgba(149, 109, 84, 0.2);
      }
    }
    .info {
      width: calc(100% - 500px);
      padding-top: 4px;
      h3 {
        font-weight: 700;
        font-size: 20px;
        color: #202020;
        .mixinEllipsis(60px; 2);
        margin-bottom: 37px;
      }
      .price {
        margin-bottom: 26px;
        width: 495px;
        height: 69px;
        line-height: 69px;
        background: #f6f6f6;
        font-size: 14px;
        color: #999999;
        padding-left: 28px;
        span {
          display: inline-block;
          strong {
            font-weight: 700;
            font-size: 26px;
            line-height: 30px;
            color: #dd0c16;
          }
          &:last-child {
            margin-left: 50px;
            padding-left: 24px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 2px;
              height: 8px;
              left: 0;
              top: 31px;
              background: #e3e3e3;
            }
          }
        }
      }
      .num {
        margin-bottom: 52px;
        color: #666;
        line-height: 26px;
        font-size: 14px;
        .input {
          display: inline-block;
          margin: 0 40px;
          position: relative;
          ::v-deep(.ant-input-number) {
            width: 54px;
            border: none;
            .ant-input-number-handler-wrap {
              border-left: none;
              border-radius: 0;
              opacity: 1;
              top: 0;
              left: 0;
              right: unset;
              width: 0;
              height: 0;
              .ant-input-number-handler-up {
                position: absolute;
                border-top: none;
                border-bottom-right-radius: 0;
                width: 26px;
                height: 26px;
                top: 0;
                right: -84px;
                opacity: 0;
                background-color: transparent;
                .ant-input-number-handler-down-inner {
                  font-size: 0;
                }
                &:hover {
                  height: 26px !important;
                }
              }
              .ant-input-number-handler-down {
                position: absolute;
                border-top-right-radius: 0;
                width: 26px;
                height: 26px;
                top: 0;
                left: -30px;
                opacity: 0;
                background-color: transparent;
                .ant-input-number-handler-up-inner {
                  font-size: 0;
                }
                &:hover {
                  height: 26px !important;
                }
              }
            }
            .ant-input-number-input-wrap {
              .ant-input-number-input {
                height: 26px;
                padding: 0 0;
                text-align: center;
                background-color: #f6f6f6;
                border: 0;
                border-radius: 0;
              }
            }
            &:focus {
              border-color: #fff;
              box-shadow: none;
            }
          }
          .icon {
            position: absolute;
            width: 26px;
            height: 26px;
            color: #acabab;
            .mixinFlex(center; center);
            background-color: #f6f6f6;
            &.plus {
              top: 0;
              right: -30px;
            }
            &.minus {
              top: 0;
              left: -30px;
            }
          }
        }
        .tips {
          color: #dd0c16;
        }
      }
      .btn {
        .ant-btn-primary {
          width: 179px;
          height: 38px;
          border-radius: 4px;
          border: 0;
          font-size: 14px;
          &.none {
            background: #999;
            color: #fff;
          }
          &.buy {
            background: #dd0c16;
            color: #fff;
          }
        }
      }
    }
  }
}

.main {
  .mixinWrap();
  .mixinFlex(space-between);
  margin-top: 20px;
  padding-bottom: 68px;
  .tit {
    padding-left: 30px;
    font-size: 16px;
    line-height: 55px;
    color: #333;
    height: 55px;
    border-bottom: 1px solid #f2f2f2;
    background-color: #fff;
    border-radius: 4px 4px 0 0;
  }
  .content {
    background-color: #fff;
    border-radius: 0 0 4px 4px;
  }
  .intro {
    width: 890px;
    .content {
      padding: 30px;
    }
  }
  .recommend {
    width: 290px;
    .content {
      padding: 20px;
    }
    .item {
      display: block;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
      .cover {
        .mixinImgWrap(250px; 190px);
        margin-bottom: 10px;
      }
      .info {
        h5 {
          font-size: 16px;
          .mixinEllipsis(24px);
          margin-bottom: 6px;
        }
        .bottom {
          line-height: 20px;
          font-size: 14px;
          strong {
            font-style: normal;
            font-weight: 700;
            color: #f5961b;
          }
          i {
            font-style: normal;
            color: #999999;
            padding-left: 30px;
            position: relative;
            &::before {
              content: "";
              position: absolute;
              width: 1px;
              height: 10px;
              left: 15px;
              top: 3px;
              background: #ddd;
            }
          }
        }
      }
    }
  }
}
</style>
